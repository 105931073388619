import React from "react";
import CanvasMenu from "@/components/Canvases/Menu";
import Layout from "@/components/Layout";

export default function Menu() {
  return (
    <Layout>
      <CanvasMenu />
    </Layout>
  );
}
