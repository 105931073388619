import React, { useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Image from "next/image";
import { IButtonHouseProps } from "./interfaces";
import { IValue, usePopup } from "@/hooks/usePopUp";
import { getNameByImage } from "@/utils/getters/getNameByImage";
import {
  ACTIONS,
  getCostToUpgrade,
  getPopUpDataByNames,
} from "@/utils/getters/getPopUpDataByName";
import { RootState } from "@/store";
import { selectHouseByName } from "@/store/island/islandSlice";
import { isNotNeedPopUp } from "@/utils/popUp";
import Sprite from "@/components/Sprite";
import Fade from "@/components/Fade";

import styles from "./styles.module.scss";
import { HouseName } from "@/constants/buildings";
import { getTranslation } from "@/utils/location";

const Button = ({ onClick, disabled, options, value }: IButtonHouseProps) => {
  const [isFading, setIsFading] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const { modal } = usePopup();
  const house = useSelector((state: RootState) =>
    selectHouseByName(state, state.island.activeNameHouse)
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const name = getNameByImage(options.image.src);
    const COIN = "coin";

    if (isNotNeedPopUp(name)) {
      setIsFading(true);
      if (name == COIN) {
        setTimeout(() => {
          onClick();
        }, 1100);
        return;
      }
      onClick();
      return;
    }

    const { title, textButton, icon, values } = getPopUpDataByNames(name);

    if (values) {
      const levelHouse = house?.level;
      const newValues = getCostToUpgrade(levelHouse);

      const updatedValues: IValue = {
        textValue: values?.textValue || "",
        values: newValues,
      };

      if (levelHouse == 0) {
        const data = getPopUpDataByNames(ACTIONS.BUILD_HOUSE);
        const text = `${getTranslation("Построить")}${" "}${getTranslation(
          HouseName[house?.house_type_name || ""]
        )}`;
        modal(data.title, data.textButton, text, data.icon, data.values).then(
          (answer) => {
            if (answer) {
              onClick();
              setIsFading(true);
            }
          }
        );
        return;
      }
      const text = `${getTranslation("Улучшить")}${" "} ${getTranslation(
        HouseName[house?.house_type_name || ""]
      )}`;

      modal(title, textButton, text, icon, updatedValues).then((answer) => {
        if (answer) {
          onClick();
          setIsFading(true);
        }
      });
    }

    if (!values) {
      const text = `${getTranslation("Улучшить")}${" "} ${getTranslation(
        HouseName[house?.house_type_name || ""]
      )}`;
      modal(title, textButton, text, icon, values).then((answer) => {
        if (answer) {
          onClick();
          setIsFading(true);
        }
      });
    }
  };

  const handleFadeEnd = () => {
    setIsAnimating(true);
    setIsFading(false);
  };

  const handleEndAnimation = () => {
    setIsAnimating(false);
  };

  if (!options) return;

  return (
    <Fade trigger={isFading} onFadeComplete={handleFadeEnd}>
      {isAnimating && options ? (
        <Sprite
          key={options.directory}
          onEnd={handleEndAnimation}
          options={options}
        />
      ) : (
        <button
          disabled={disabled}
          onClick={handleClick}
          className={`${styles.button} ${disabled ? styles.disabled : ""}`}
        >
          {value && value > 0 ? (
            <span className={styles.button__quantity}>{value}</span>
          ) : null}
          <Image
            className={styles.button__image}
            src={options.image}
            alt="The picture of coin"
            width={35}
            height={35}
          />
        </button>
      )}
    </Fade>
  );
};

export default Button;
